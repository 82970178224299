import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.tableData,
    border: "",
    style: {"width":"100%"},
    "row-key": "AreaId"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        prop: "cityname",
        label: "省份"
      }),
      _createVNode(_component_el_table_column, {
        prop: "y",
        label: "年"
      }),
      _createVNode(_component_el_table_column, {
        prop: "m",
        label: "月"
      }),
      _createVNode(_component_el_table_column, {
        prop: "tel",
        label: "用户号码"
      }),
      _createVNode(_component_el_table_column, {
        prop: "nn",
        label: "预约数"
      })
    ]),
    _: 1
  }, 8, ["data"]))
}

import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Api } from "@/assets/Api";

export default defineComponent({
  name: "AdminSMS",
  components: {},
  setup() {
    let data = reactive<any>({
      tableData: [],
    });

    const RegTelReportList = () => {
      Api.Api2.PlanRegTelReport().then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.tableData = res.data;
        } else {
          Api.Msg.warning(res.data);
          data.tableData = [];
        }
      });
    };

    onMounted(() => {
      RegTelReportList();
    });

    return {
      ...toRefs(data),
    };
  },
});
